import React from 'react'
import { graphql } from 'gatsby'
// import Image from 'gatsby-image'
// eslint-disable-next-line
import PropTypes from 'prop-types'

import {
  Heading,
  Button,
  Container,
  Hero,
  // Icon,
  Section,
  Box,
  // Level,
  // Tile,
  Columns
  // Content
} from 'react-bulma-components'

import Layout from '../components/layouts/index'
import SEO from '../components/seo'

import ArtPortfolioSectionBg from '../components/ArtPortfolioSectionBg'

import JSIcon from '../../content/assets/icons/tech-stack/js.svg'
import VueIcon from '../../content/assets/icons/tech-stack/vue.svg'
import SwiftIcon from '../../content/assets/icons/tech-stack/swift.svg'
import ReactIcon from '../../content/assets/icons/tech-stack/react-icon.svg'

import ScalaIcon from '../../content/assets/icons/tech-stack/backend/Scala.svg'
import JavaIcon from '../../content/assets/icons/tech-stack/backend/Java.svg'
import PythonIcon from '../../content/assets/icons/tech-stack/backend/Python.svg'
import AWSIcon from '../../content/assets/icons/tech-stack/backend/AWS.svg'
import DevOpsIcon from '../../content/assets/icons/tech-stack/backend/DevOps.svg'
import ETLIcon from '../../content/assets/icons/tech-stack/backend/ETL.svg'
import BigDataIcon from '../../content/assets/icons/tech-stack/backend/BigData.svg'
import MicroserviceIcon from '../../content/assets/icons/tech-stack/backend/Microservice.svg'
import CICDIcon from '../../content/assets/icons/tech-stack/backend/CICD.svg'
import DistributedSystemIcon from '../../content/assets/icons/tech-stack/backend/DistributedSystem.svg'
import MLIcon from '../../content/assets/icons/tech-stack/backend/ML.svg'
import ElasticsearchIcon from '../../content/assets/icons/tech-stack/backend/Elasticsearch.svg'
import NodeIcon from '../../content/assets/icons/tech-stack/backend/Node.svg'
import AAIcon from '../../content/assets/icons/tech-stack/backend/AA.svg'

import { Tablet, Mobile } from '../utils/presets'

// eslint-disable-next-line
const IconBox = ({ children, ...props }) =>
  (
  <div
    css={{
      display: 'flex',
      backgroundColor: '#F1F2FD',
      borderRadius: 6,
      padding: '1rem',
      margin: '.5rem',
      width: 65
    }}
    {...props}
  >
    {children}
  </div>
)

// eslint-disable-next-line
const DescriptionBlock = ({ title, ...props }) =>
(
  <div
    css={{
      display: 'flex'
    }}
    {...props}
  >
    <span css={{ fontWeight: 'bold' }}>{title}</span>
  </div>
)

class TechPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        bodyInContainer={false}
        className="index"
      >
        <SEO title="Technology stack" />
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 150 }}>
              <Container className="has-text-centered">
                <Section>
                  <Heading size={1} renderAs="h1">
                    Technology Stack
                  </Heading>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <Container className="has-text-centered">
              <Section>
                <Box>
                  <Section>
                    <Heading size={1} renderAs="h2">
                      Frontend
                    </Heading>
                    <Columns>
                      <Columns.Column offset={1} size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <JSIcon />
                          </IconBox>
                          <DescriptionBlock title="JS" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <VueIcon />
                          </IconBox>
                          <DescriptionBlock title="Vue" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <ReactIcon />
                          </IconBox>
                          <DescriptionBlock title="React" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <SwiftIcon />
                          </IconBox>
                          <DescriptionBlock title="Swift" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <ReactIcon />
                          </IconBox>
                          <DescriptionBlock title="React Native" />
                        </div>
                      </Columns.Column>
                    </Columns>
                  </Section>
                  <Section>
                    <Heading size={1} renderAs="h2">
                      Backend
                    </Heading>
                    <Columns>
                      <Columns.Column offset={1} size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <ScalaIcon />
                          </IconBox>
                          <DescriptionBlock title="Scala" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <JavaIcon />
                          </IconBox>
                          <DescriptionBlock title="Java" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <PythonIcon />
                          </IconBox>
                          <DescriptionBlock title="Python" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <AWSIcon />
                          </IconBox>
                          <DescriptionBlock title="AWS" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <DevOpsIcon />
                          </IconBox>
                          <DescriptionBlock title="Dev Ops" />
                        </div>
                      </Columns.Column>
                    </Columns>
                    <Columns>
                      <Columns.Column offset={1} size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <ETLIcon />
                          </IconBox>
                          <DescriptionBlock title="ETL" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <BigDataIcon />
                          </IconBox>
                          <DescriptionBlock title="Big Data" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <MicroserviceIcon />
                          </IconBox>
                          <DescriptionBlock title="Microservice" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <CICDIcon />
                          </IconBox>
                          <DescriptionBlock title="CI/CD" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <DistributedSystemIcon />
                          </IconBox>
                          <DescriptionBlock title="Distributed System" />
                        </div>
                      </Columns.Column>
                    </Columns>
                    <Columns>
                      <Columns.Column offset={1} size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <MLIcon />
                          </IconBox>
                          <DescriptionBlock title="ML" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <ElasticsearchIcon />
                          </IconBox>
                          <DescriptionBlock title="Elasticsearch" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <NodeIcon />
                          </IconBox>
                          <DescriptionBlock title="Node.js" />
                        </div>
                      </Columns.Column>
                      <Columns.Column size={2}>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <IconBox>
                            <AAIcon />
                          </IconBox>
                          <DescriptionBlock title="Apache Airflow" />
                        </div>
                      </Columns.Column>
                    </Columns>
                  </Section>
                </Box>
              </Section>
            </Container>
          </Hero.Body>
        </Hero>
        <Hero size="small">
          <Hero.Body className="is-paddingless">
            <ArtPortfolioSectionBg style={{ minHeight: 300 }}>
              <Container>
                <Section>
                  <Box>
                    <Section
                      className="custom-section"
                      css={{
                        [Mobile]: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                        },
                        [Tablet]: {
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          paddingTop: '1rem',
                          paddingBottom: '1rem',
                          flexDirection: 'inherit'
                        }
                      }}
                    >
                      <div>
                        <Heading size={2} renderAs="h2">
                          Let&apos;s talk
                        </Heading>
                        <Heading subtitle size={5} renderAs="h3">
                          Software development solutions that <br /> solve real
                          business needs.
                        </Heading>
                      </div>
                      <Button
                        color="primary"
                        onClick={this.openMailTo}
                        className="responsive-button"
                        css={{
                          display: 'flex',
                          fontWeight: 'bold',
                          [Mobile]: {
                            marginTop: '2rem'
                          },
                          [Tablet]: {
                            marginTop: 0
                          }
                        }}
                      >
                        GET ESTIMATE
                      </Button>
                    </Section>
                  </Box>
                </Section>
              </Container>
            </ArtPortfolioSectionBg>
          </Hero.Body>
        </Hero>
      </Layout>
    )
  }

  decodeSupportURL = () => {
    const encoded = 'ifmmpAubohjcmz/ufdi'
    let link = ''
    for (let i = 0; i < encoded.length; i++)
      link += String.fromCharCode(encoded.charCodeAt(i) - 1)

    return link
  }

  openMailTo = e => {
    e.preventDefault()
    const link = this.decodeSupportURL()

    window.location.href = `mailto:${link}`
  }
}

TechPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}

export default TechPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
